import { addNewContactsField, editContactsField } from "core/store/entities/List/list.actions";
import { listSelectors } from "core/store/entities/List/list.selectors";
import { ContactFieldDataType, ContactField, SelectOption } from "core/types";
import { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { CustomColumnFormType } from "../..";
import dayjs from "dayjs";

const generateChoiceKey = (choice: string) => choice.toLocaleLowerCase().split(" ").join("_");

const getChoicesData = (choices: string[]) => ({
  choice: {
    choices: Object.fromEntries(choices.map(choice => [generateChoiceKey(choice), choice])),
    default: generateChoiceKey(choices[0])
  },
  multichoice: {
    choices: Object.fromEntries(choices.map(choice => [generateChoiceKey(choice), choice])),
    default: []
  }
});

const defaultValueByDataType: Partial<Record<ContactFieldDataType, any>> = {
  date: dayjs().format("YYYY-MM-DD"),
  float: 0.0,
  integer: 0,
};

const options: SelectOption<ContactFieldDataType>[] = [
  { label: "Text", value: "character" },
  { label: "Date", value: "date" },
  { label: "Number", value: "float" },
  { label: "Category", value: "choice" },
  { label: "Multiple-choice", value: "multichoice" },
  { label: "Boolean", value: "boolean" }
];

const defaultValues: CustomColumnFormType = {
  label: "",
  data_type: "empty_option",
  choices: [""]
};

export const useCustomColumnForm = (
  selectedColumn?: ContactField,
) => {
  const editContactsFieldError = useSelector(listSelectors.editContactsFieldError);
  const addNewContactsFieldError = useSelector(listSelectors.addNewContactsFieldError);
  const listError = useSelector(listSelectors.error);
  const isError = editContactsFieldError || addNewContactsFieldError;
  const dispatch = useDispatch();
  const {
    control,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
    setError
  } = useForm<CustomColumnFormType>({
    defaultValues: {
      label: "",
      data_type: "empty_option",
      choices: [""]
    }
  });
  const choicesFieldArray = useFieldArray({
    control,
    name: "choices"
  });

  const selectedType = watch("data_type");

  const onSubmit = (values: CustomColumnFormType) => {
    const { data_type, choices, label } = values;
    const choicesData =
      data_type === "choice" || data_type === "multichoice"
        ? getChoicesData(choices)[data_type]
        : {};

    const editableData = {
      label,
      ...choicesData
    };

    dispatch(
      selectedColumn
        ? editContactsField({ id: selectedColumn.id, data: editableData })
        : addNewContactsField({
          ...editableData,
          data_type,
          read_only: false,
          required: false,
          default: defaultValueByDataType[data_type]
        })
    );
  };

  useEffect(() => {
    setValue("label", selectedColumn?.label ?? "");
    setValue("data_type", selectedColumn?.data_type ?? "empty_option");
    setValue("choices", Object.values(selectedColumn?.choices ?? { choice: "" }));
  }, [selectedColumn?.label, selectedColumn?.data_type, selectedColumn?.choices]);
  useEffect(() => {
    if (isError && typeof listError === "object") {
      Object.entries(listError as object).forEach(([key, value]) => {
        if (key in defaultValues)
          setError(key, { type: "custom", message: Array.isArray(value) ? value[0] : value });
      });
    }
  }, [isError, listError]);

  return {
    control,
    errors,
    onSubmit: handleSubmit(onSubmit),
    selectedType,
    choicesFieldArray,
    dataTypeOptions: options
  };
};
