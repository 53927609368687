import { RootState } from "core/store/reducers";
import { createSelector } from "reselect";
import { listSelectors } from "../List/list.selectors";
import { contactsSelectors } from "../Contacts/contacts.selectors";

const selectSelf = (state: RootState) => state.contact;

const selectContactFields = listSelectors.listFields;

const selectContactEditableFields = createSelector(selectContactFields, listFields =>
  listFields?.filter(field => !field.read_only)
);

export const contactSelectors = {
  contact: createSelector([contactsSelectors.contacts, (_, id?: string) => id], (contacts, id) =>
    contacts?.find(contact => contact.id === id)
  ),
  contactFields: selectContactFields,
  contactEditableFields: selectContactEditableFields,
  contactOptionalEditableFields: createSelector(selectContactEditableFields, fields =>
    fields?.filter(field => !field.required)
  ),
  contactReadOnlyFields: createSelector(selectContactFields, fields =>
    fields?.filter(field => field.read_only)
  ),
  contactRequiredEditableFields: createSelector(selectContactEditableFields, fields =>
    fields?.filter(field => field.required)
  ),

  createContactStatus: createSelector(selectSelf, contactState => contactState.createContactStatus),
  error: createSelector(selectSelf, contactState => contactState.error)
};
