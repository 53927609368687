import { deleteRequest, getRequest, postRequest, patchRequest } from "core/api/base";

const contactsListsUrl = "/mailsystem/api/v1/contacts-lists";

export class ListContactsService {
  private listId: string;
  private url: string;

  constructor(id: string) {
    this.listId = id;
    this.url = `${contactsListsUrl}/${this.listId}/contacts`;
  }

  createContact = (data: object) => {
    return postRequest({ url: `${this.url}/`, data });
  };

  editContact = (id: string, data: object) => {
    return patchRequest({ url: `${this.url}/${id}`, data });
  };

  getContacts = () => {
    const params = new URLSearchParams(window.location.search);
    return getRequest({ url: this.url, params });
  };
  deleteContacts = (ids: string[]) => {
    return deleteRequest({ url: this.url, data: { list_ids: ids } });
  };
  startExportContacts = () => {
    return getRequest({ url: `${contactsListsUrl}/${this.listId}/export-contacts` });
  };

  getExportContactsProgress = (taskId: string) => {
    return getRequest({ url: `${contactsListsUrl}/export-status/${taskId}` });
  };
  startImportContacts = (
    file: any,
    payload: { headers_map: Record<string, string>; update_existing: boolean }
  ) => {
    const stringPayload = JSON.stringify(payload);
    return postRequest({ url: `${contactsListsUrl}/${this.listId}/import-contacts/`, data: { file, payload: stringPayload }, headers: { 'Content-Type': 'multipart/form-data' } });
  };
}
