import { Alert, FormControl, FormLabel, Stack } from "@mui/material";
import { ContactField, SelectOption } from "core/types";
import { useContactForm } from "./useContactForm";
import { Controller } from "react-hook-form";
import { fieldTypeInputMap } from "helpers/fieldTypeInputMap";

const getOptions = (choices: ContactField["choices"]): SelectOption<any>[] =>
  Object.entries(choices).map(([key, value]) => ({
    label: value,
    value: key
  }));

type Props = {
  formName: string;
};

export const ContactForm = ({ formName }: Props) => {
  const { control, errors, onSubmit, editableFields, fieldsError } = useContactForm();
  return (
    <Stack
      id={formName}
      component="form"
      gap={3}
      sx={{ py: 3, pb: 6, px: { xs: 2, sm: 5 } }}
      onSubmit={onSubmit}
    >
      {editableFields.map(
        ({ id, label, data_type, required, pattern, length, choices, column_name }, index) => {
          const InputNode = fieldTypeInputMap[data_type];
          if (!InputNode) return null;
          const labelId = `${id}-label`;
          const options: SelectOption<any>[] = choices ? getOptions(choices) : [];
          return (
            <Controller
              control={control}
              key={id}
              rules={{
                required: { value: required, message: "This field is required" },
                pattern: pattern
                  ? { value: new RegExp(pattern), message: "Invalid format" }
                  : undefined,
                maxLength: length
              }}
              render={({ field }) =>
                data_type === "boolean" || data_type === "multichoice" ? (
                  <InputNode
                    id={id}
                    error={!!errors?.[column_name]?.message}
                    helperText={errors?.[column_name]?.message}
                    options={options}
                    autoFocus={index === 0}
                    label={label}
                    sx={{
                      fontSize: "15.75px"
                    }}
                    checkBoxProps={{
                      size: null
                    }}
                    {...field}
                  />
                ) : (
                  <FormControl>
                    <FormLabel
                      htmlFor={id}
                      sx={{ pb: "0.75rem", color: theme => theme.palette.black }}
                    >
                      {label}
                    </FormLabel>
                    <InputNode
                      id={id}
                      aria-labelledby={labelId}
                      error={!!errors?.[column_name]?.message}
                      helperText={errors?.[column_name]?.message}
                      options={options}
                      autoFocus={index === 0}
                      {...field}
                    />
                  </FormControl>
                )
              }
              name={column_name}
            />
          );
        }
      )}
      {!!fieldsError && <Alert severity="error">Error while creating new contact.</Alert>}
    </Stack>
  );
};
