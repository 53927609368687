import { put, call, takeLatest, select } from "redux-saga/effects";
import { ListContactsServiceInstance } from "../List/list.saga";
import { PayloadAction } from "@reduxjs/toolkit";
import { importFlowSelectors } from "./importFlow.selectors";
import { importFlowActions } from "./importFlow.slice";

let taskId;

function* importStartHandler() {
  try {
    if (ListContactsServiceInstance) {
      const headersMap = yield select(importFlowSelectors.newCsvHeaders);
      const replaceContacts = yield select(importFlowSelectors.shouldReplaceContacts);
      const file = yield select(importFlowSelectors.selectedFile);
      const { task_id } = yield call(
        ListContactsServiceInstance.startImportContacts,
        file,
        {
          headers_map: headersMap,
          update_existing: replaceContacts
        }
      );
      taskId = task_id;
      yield put(importFlowActions.importStartSuccess());
    }
  } catch (error) {
    yield put(importFlowActions.importStartFailed(error));
  }
}

export default function* importFlowSaga() {
  yield takeLatest(importFlowActions.importStart.type, importStartHandler);
}
