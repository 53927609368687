import { RootState } from "core/store/reducers";
import { createSelector } from "reselect";

export const doNotImportKey = "do_not_import"

const selectSelf = (state: RootState) => state.importFlow;
const selectColumnsFieldsMap = createSelector(
  selectSelf,
  importFlowState => importFlowState.columnsFieldsMap
);

const selectNotImportedFields = createSelector(selectColumnsFieldsMap, columnsFieldsMap =>
  Object.values(columnsFieldsMap).filter(fieldMap => fieldMap?.mapped_column === doNotImportKey)
);
const selectNotImportedFieldsCount = createSelector(
  selectNotImportedFields,
  notImportedFields => notImportedFields.length
);

const selectInitColumnsFields = createSelector(selectSelf, importFlowState =>
  Object.values(importFlowState.initColumnsFieldsMap)
);

const selectInitColumnsFieldsCount = createSelector(
  selectInitColumnsFields,
  initColumnsFields => initColumnsFields.length
);

const selectMappedColumn = createSelector(
  [selectColumnsFieldsMap, (_, columnName?: string) => columnName],
  (mapping, columnName) => columnName && mapping[columnName]?.mapped_column
);

export const importFlowSelectors = {
  selectedFile: createSelector(selectSelf, importFlowState => importFlowState.selectedFile),
  selectedFileTextContent: createSelector(
    selectSelf,
    importFlowState => importFlowState.selectedFileTextContent
  ),
  shouldReplaceContacts: createSelector(
    selectSelf,
    importFlowState => importFlowState.shouldReplaceContacts
  ),
  selectedColumnName: createSelector(
    selectSelf,
    importFlowState => importFlowState.selectedColumnName
  ),
  columnsFieldsMap: selectColumnsFieldsMap,
  initColumnsFieldsMap: createSelector(selectSelf, importFlowState =>
    Object.values(importFlowState.initColumnsFieldsMap)
  ),
  initFieldsCount: selectInitColumnsFieldsCount,
  addedFieldsCount: createSelector(
    selectInitColumnsFieldsCount,
    selectNotImportedFieldsCount,
    (initColumnsFieldsCount, notImportedFieldsCount) =>
      initColumnsFieldsCount - notImportedFieldsCount
  ),
  isNotImportedField: createSelector(
    [selectSelf, (_, columnName?: string) => columnName],
    (state, columnName) => columnName && state.columnsFieldsMap[columnName]?.mapped_column === doNotImportKey
  ),
  mappedColumn: selectMappedColumn,
  isDuplicatedMappedColumn: createSelector(
    [selectColumnsFieldsMap, (_, mappedColumn?: string) => mappedColumn],
    (mapping, mappedColumn) => mappedColumn !== doNotImportKey && Object.values(mapping).filter(m => m?.mapped_column === mappedColumn).length > 1
  ),
  hasDuplicatedMappedColumns: createSelector(selectColumnsFieldsMap, mapping => {
    const filteredMappedColumns = Object.values(mapping).filter(m => !!m && m.mapped_column !== doNotImportKey);
    const uniqueMappings = [...new Set(filteredMappedColumns)]
    return uniqueMappings.length < filteredMappedColumns.length
  }),
  shouldAddNewField: createSelector(selectColumnsFieldsMap, mapping => {
    const definedMappedColumns = Object.values(mapping).filter((m) => !!m?.mapped_column);
    return Object.values(mapping).length > definedMappedColumns.length
  }),
  importStartSuccessful: createSelector(selectSelf, state => state.importStatus === "success"),
  importStartFailed: createSelector(selectSelf, state => state.importStatus === "error"),
  newCsvHeaders: createSelector(selectColumnsFieldsMap, mapping => Object.fromEntries(Object.values(mapping).map((m) => [m?.column_name, m?.label])))
};
