import { PayloadAction } from "@reduxjs/toolkit";
import { ImportFlowState } from "./importFlow.slice";
import { ContactFieldBase, MappedColumn } from "core/types";

export const importFlowReducers = {
  setSelectedFile: (state: ImportFlowState, { payload }: PayloadAction<File | null>) => {
    state.selectedFile = payload;
    if (payload === null) {
      delete state.selectedFileTextContent;
    }
  },
  setSelectedFileTextContent: (state: ImportFlowState, { payload }: PayloadAction<string>) => {
    state.selectedFileTextContent = payload;
  },
  setShouldReplaceContacts: (state: ImportFlowState, { payload }: PayloadAction<boolean>) => {
    state.shouldReplaceContacts = payload;
  },
  setSelectedColumnName: (state: ImportFlowState, { payload }: PayloadAction<string | null>) => {
    state.selectedColumnName = payload;
  },
  setInitFieldsMap: (
    state: ImportFlowState,
    { payload }: PayloadAction<Array<ContactFieldBase & MappedColumn>>
  ) => {
    if (state.addedNewFields) return;
    state.initColumnsFieldsMap = Object.fromEntries(
      payload.map(contactField => [contactField.column_name, contactField])
    );
    state.columnsFieldsMap = Object.fromEntries(
      payload.map(({ column_name, mapped_column, label }) => [
        column_name,
        { label, mapped_column, column_name }
      ])
    );
  },
  addColumnsFieldsMapping: (
    state: ImportFlowState,
    { payload }: PayloadAction<{ label: string; columnName: string; mappedColumn?: string }>
  ) => {
    const { label, mappedColumn, columnName } = payload;
    state.columnsFieldsMap[payload.columnName] = {
      label,
      mapped_column: mappedColumn,
      column_name: columnName
    };
  },
  resetColumnsFieldsMaps: (state: ImportFlowState) => {
    state.initColumnsFieldsMap = {};
    state.columnsFieldsMap = {};
    state.addedNewFields = false;
    delete state.selectedColumnName;
  },

  importStart: (state: ImportFlowState) => {
    state.importStatus = "loading";
  },
  importStartSuccess: (state: ImportFlowState) => {
    state.importStatus = "success";
  },
  importStartFailed: (state: ImportFlowState, { payload }: PayloadAction<unknown>) => {
    state.importStatus = "error";
    state.error = payload;
  }
};
