import { ContactField, ContactFieldBase, MappedColumn } from "core/types";
import { importFlowReducers } from "./importFlow.reducers";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { listActions } from "../List/list.slice";
import { Status } from "core/constants/status";

export type ImportFlowState = {
  selectedFile: File | null;
  selectedFileTextContent?: string;
  shouldReplaceContacts: boolean;
  selectedColumnName?: string | null;
  initColumnsFieldsMap: Record<string, ContactFieldBase>;
  addedNewFields: boolean;
  columnsFieldsMap: Record<
    string,
    { label: string; column_name: string; mapped_column?: string } | null | undefined
  >;
  importStatus: Status;
  error?: unknown;
};

const initialState: ImportFlowState = {
  selectedFile: null,
  shouldReplaceContacts: true,
  initColumnsFieldsMap: {},
  addedNewFields: false,
  columnsFieldsMap: {},
  importStatus: "idle"
};

const importFlowSlice = createSlice({
  name: "importFlow",
  initialState,
  reducers: importFlowReducers,
  extraReducers: builder =>
    builder.addCase(
      listActions.addNewContactsFieldSuccess,
      (state: ImportFlowState, { payload }: PayloadAction<ContactField>) => {
        const currentSelectedColumnName = state.selectedColumnName;
        if (currentSelectedColumnName) {
          const { column_name, label } = payload;
          state.columnsFieldsMap[currentSelectedColumnName] = {
            mapped_column: column_name,
            column_name: currentSelectedColumnName,
            label
          };
        }
        state.addedNewFields = true;
      }
    )
});

const importFlowActions = importFlowSlice.actions;
const importFlowReducer = importFlowSlice.reducer;

export { importFlowActions, importFlowReducer };
