import { importStart } from "core/store/entities/ImportFlow/importFlow.actions";
import { importFlowSelectors } from "core/store/entities/ImportFlow/importFlow.selectors";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { LISTS_SCREEN } from "core/constants";
import { Button } from "ui/components";
import { theme } from "ui/theme";
import { useEffect } from "react";

export const ContinueButton = () => {
  const { list_id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hasDuplicatedMappedColumns = useSelector(importFlowSelectors.hasDuplicatedMappedColumns);
  const shouldAddNewField = useSelector(importFlowSelectors.shouldAddNewField);
  const importStartSuccess = useSelector(importFlowSelectors.importStartSuccessful);
  const handleClick = () => {
    dispatch(importStart());
  };

  useEffect(() => {
    if (importStartSuccess) {
      const newPathname = `${LISTS_SCREEN}/${list_id}`;
      navigate(newPathname);
    }
  }, [importStartSuccess]);
  return (
    <Button
      sx={{
        color: theme.palette.white.main
      }}
      onClick={handleClick}
      disabled={hasDuplicatedMappedColumns || shouldAddNewField}
    >
      Continue
    </Button>
  );
};
