import axios from "axios";
import { LOGIN_SCREEN, PROFILE_TOKEN, DOMAIN, HTTP_UNAUTHORIZED } from "core/constants";
import { history } from "core/store";

export const http = axios.create({
  baseURL: DOMAIN,
  headers: {
    "Content-Type": "application/json",
  }
});

http.interceptors.response.use(
  response => response,
  error => {
    const { status } = error.response;
    if (status === HTTP_UNAUTHORIZED) {
      localStorage.clear();
      history.push(LOGIN_SCREEN);
    }
    return Promise.reject(error);
  }
);

const request = async ({ method, url, data, params, auth, token, headers }) => {
  try {
    const response = await http({
      method,
      url: DOMAIN + url,
      data,
      params,
      headers: {
        ...headers,
        Authorization: auth ? `Token ${localStorage.getItem(token)}` : null
      }
    });
    return response?.data;
  } catch (error) {
    throw error.response?.data;
  }
};

const getRequest = ({ url, params, auth = true, token = PROFILE_TOKEN, headers = {} }) =>
  request({ method: "GET", url, params, auth, token, headers });
const postRequest = ({ url, data, auth = true, token = PROFILE_TOKEN, headers = {} }) =>
  request({ method: "POST", url, data, auth, token, headers });
const putRequest = ({ url, data, auth = true, token = PROFILE_TOKEN, }) =>
  request({ method: "PUT", url, data, auth, token, });
const patchRequest = ({ url, data, auth = true, token = PROFILE_TOKEN, }) =>
  request({ method: "PATCH", url, data, auth, token, });
const deleteRequest = ({ url, data, auth = true, token = PROFILE_TOKEN, }) =>
  request({ method: "DELETE", url, data, auth, token, });

export { getRequest, postRequest, putRequest, patchRequest, deleteRequest };
