import { Select } from "ui/components";
import { useRenderCustomColumnSelect } from "./useRenderCustomColumnSelect";
import { useSelector } from "react-redux";
import { importFlowSelectors } from "core/store/entities/ImportFlow/importFlow.selectors";

type Props = {
  columnName: string;
  mappedColumnName?: string;
  value?: string | null;
  disabled?: boolean;
};

export const CustomColumnSelect = ({ columnName }: Props) => {
  const mappedColumn = useSelector(state => importFlowSelectors.mappedColumn(state, columnName));
  const isDuplicatedMappedColumn = useSelector(state =>
    importFlowSelectors.isDuplicatedMappedColumn(state, mappedColumn)
  );
  const { renderSelectedValue, options, onChange, disabled } = useRenderCustomColumnSelect(
    columnName,
    mappedColumn
  );

  const handleChange = (e: PointerEvent) => {
    onChange(columnName, e.target?.value);
  };

  return (
    <Select
      value={mappedColumn ?? ""}
      onChange={handleChange}
      sx={{ minWidth: 200, mx: 1 }}
      options={options}
      disabled={disabled}
      error={!mappedColumn || isDuplicatedMappedColumn}
      label="Select field name"
      displayEmpty
      renderValue={renderSelectedValue}
    />
  );
};
