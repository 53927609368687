import { importFlowActions } from "./importFlow.slice";

export const {
  addColumnsFieldsMapping,

  importStart,
  importStartSuccess,
  importStartFailed,

  setSelectedFile,
  setSelectedFileTextContent,
  setShouldReplaceContacts,
  setSelectedColumnName,
  setInitFieldsMap,

  resetColumnsFieldsMaps,
} = importFlowActions;
